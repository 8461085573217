import React, { Component } from 'react';
import { childrenPropType } from '../propTypes.js';
import { GRAY_HELP } from '../colors'

const styles = {};

export default class Label extends Component {

  static propTypes = {
    children: childrenPropType,
  };

  constructor( props ) {
    super( props );

    this.state = {

    };
  }

  render() {
    return (
      <label style={ styles.container }>
        {
          this.props.children
        }
      </label>
    );
  }

}

Object.assign( styles, {
  container: {
    display: 'block',
    color: GRAY_HELP,
    fontSize: '12px',
    paddingLeft: '0.4rem',
    marginBottom: '0.2rem',
  }
} );
