import React from 'react';
import PropTypes from 'prop-types';
import {PageHeading} from '../widgets/headings.js';
import {Disclaimer} from '../widgets';

import './thankYouPage.css';
import {GRAY_HELP} from '../colors';

const styles = {};

export const InvalidToken = () => {
  return (
    <div style={ styles.container }>
      <span style={ styles.filler } />
      <PageHeading>Already used</PageHeading>
      <img style={styles.animatedImg} src={require('../img/404_SquirrelMishap_Animated_1500px@2x-transparent.png')} alt={'Error 404...'}/>
      <p style={ styles.info }>
        The token we tried to use to log in to your new academy has already been used.
        You can log in via the link the email we send to you when the academy was created.
      </p>
      <Disclaimer>
        { 'If you didn’t get an email, please check your spam folder and then contact our ' }
        <a href="mailto:support@learnifier.com">support team</a>.
      </Disclaimer>
      <span style={ styles.filler } />
    </div>
  );
};

InvalidToken.propTypes = {
  firstName: PropTypes.string.isRequired,
};


Object.assign( styles, {
  container: {
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },

  info: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight:600,
    marginTop: '2rem',
    color: GRAY_HELP
  },

  filler: {
    flex: '1 0 0',
  },

  envelope: {
    position: 'relative',
    overflow: 'hidden'
  },

  animatedImg: {
    width: '100%',
    textAlign: 'center'
  },

  thankYou: {
    width: '100%',
  },
} );
