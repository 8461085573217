import React, { Component } from 'react';
import { PageHeading } from '../../widgets/headings.js';

const styles = {};

export default class RealmCancelled extends Component {

  static propTypes = {
  };

  constructor( props ) {
    super( props );

    this.state = {

    };
  }

  render() {
    return (
      <div style={ styles.container }>
        <span style={ styles.filler } />
        <PageHeading>This Learnifier domain has been cancelled.</PageHeading>
        <p style={ styles.info }>
          Contact <a href="mailto:support@learnifier.com">support@learnifier.com</a> if you want to open the domain again or have any other questions
        </p>
        <span style={ styles.filler } />
      </div>
    );
  }
}

Object.assign( styles, {
  container: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },

  info: {
    textAlign: 'center',
    fontSize: '1.5rem',
    marginTop: '2rem',
  },

  filler: {
    flex: '1 0 0',
  }
} );
