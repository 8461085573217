export const GET = {
  method: 'GET',
  mode: 'cors',
  headers: {
    Accept: 'application/json',
  },
};

export const GET_NO_CORS = {
  method: 'GET',
  mode: 'no-cors',
  headers: {
    Accept: 'application/json',
  },
};

export const POST = {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify( {} ),
};
