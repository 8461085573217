import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {};

export default class Select extends Component {

  static propTypes = {
    style: PropTypes.object,
    options: PropTypes.arrayOf( PropTypes.shape( {
      key: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    } ) ),

    value: PropTypes.any,
  };

  constructor( props ) {
    super( props );

    this.state = {

    };
  }

  render() {
    return (
      <select style={ this.props.style }>
        {
          this.props.options.map( ( option ) => (
            <option
              key={ option.key }
              value={ option.value }
              selected={ this.props.value && option.value === this.props.value }
            >
              { option.label }
            </option>
          ) )
        }
      </select>
    );
  }

}

Object.assign( styles, {
  container: {

  }
} );
