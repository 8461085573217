import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';

const styles = {};

class Icon extends Component {

  static propTypes = {
    onClick: PropTypes.func,
  };

  handleClick = ( event ) => {
    if ( this.props.onClick ) {
      this.props.onClick( event );
    }
  }

  render() {
    return (
      <img
        src={ this.props.src }
        style={ [ styles.container, this.props.onClick && styles.clickable, this.props.style ] }
        onClick={ this.handleClick }
        role="presentation"
      />
    );
  }

}

Object.assign( styles, {
  container: {
    display: 'block',
  },

  clickable: {
    cursor: 'pointer',
  },
} );

export default radium( Icon );
