import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'match-media';
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './index.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {App} from './App';
import RealmMessage from './message/components/RealmMessage';
import {Tracker} from "./tracking/Tracker";

const realmPath = window.contextPath+'realm/:mode';
ReactDOM.render((
  <BrowserRouter>
    <Tracker>
      <Switch>
        <Route exact path={realmPath} component={RealmMessage}/>
        <Route component={App}/>
      </Switch>
    </Tracker>
  </BrowserRouter>
), document.getElementById('root'));
