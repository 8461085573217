import React, {useEffect} from 'react';
import {withRouter} from 'react-router';

const _Tracker = ({children, location}) => {
  useEffect(() => {
    if (window.mixpanel) {
      const page = location.pathname + location.search;
      window.mixpanel.track('signup', {path: page});
    }
  }, [location.pathname, location.search]);

  return children;
};

export const Tracker = withRouter(_Tracker);
