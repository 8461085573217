import React, { Component } from 'react';
import { childrenPropType } from '../propTypes.js';

const styles = {};

class FormContainer extends Component {

  static propTypes = {
    children: childrenPropType
  };

  constructor( props ) {
    super( props );

    this.state = {

    };
  }

  render() {
    return (
      <div style={ styles.container }>
        { this.props.children }
      </div>
    );
  }

}

Object.assign( styles, {
  container: {
    width: '100%',
  },


} );

export default FormContainer;
