export default {
	"AD": {
		"name": "Andorra",
		"zones": [
			"Europe/Andorra"
		]
	},
	"AE": {
		"name": "United Arab Emirates",
		"zones": [
			"Asia/Dubai"
		]
	},
	"AF": {
		"name": "Afghanistan",
		"zones": [
			"Asia/Kabul"
		]
	},
	"AG": {
		"name": "Antigua & Barbuda",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"AI": {
		"name": "Anguilla",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"AL": {
		"name": "Albania",
		"zones": [
			"Europe/Tirane"
		]
	},
	"AM": {
		"name": "Armenia",
		"zones": [
			"Asia/Yerevan"
		]
	},
	"AO": {
		"name": "Angola",
		"zones": [
			"Africa/Lagos"
		]
	},
	"AQ": {
		"name": "Antarctica",
		"zones": [
			"Antarctica/Casey",
			"Antarctica/Davis",
			"Antarctica/DumontDUrville",
			"Antarctica/Mawson",
			"Antarctica/Palmer",
			"Antarctica/Rothera",
			"Antarctica/Syowa",
			"Antarctica/Troll",
			"Antarctica/Vostok",
			"Pacific/Auckland"
		]
	},
	"AR": {
		"name": "Argentina",
		"zones": [
			"America/Argentina/Buenos_Aires",
			"America/Argentina/Cordoba",
			"America/Argentina/Salta",
			"America/Argentina/Jujuy",
			"America/Argentina/Tucuman",
			"America/Argentina/Catamarca",
			"America/Argentina/La_Rioja",
			"America/Argentina/San_Juan",
			"America/Argentina/Mendoza",
			"America/Argentina/San_Luis",
			"America/Argentina/Rio_Gallegos",
			"America/Argentina/Ushuaia"
		]
	},
	"AS": {
		"name": "Samoa (American)",
		"zones": [
			"Pacific/Pago_Pago"
		]
	},
	"AT": {
		"name": "Austria",
		"zones": [
			"Europe/Vienna"
		]
	},
	"AU": {
		"name": "Australia",
		"zones": [
			"Australia/Lord_Howe",
			"Antarctica/Macquarie",
			"Australia/Hobart",
			"Australia/Currie",
			"Australia/Melbourne",
			"Australia/Sydney",
			"Australia/Broken_Hill",
			"Australia/Brisbane",
			"Australia/Lindeman",
			"Australia/Adelaide",
			"Australia/Darwin",
			"Australia/Perth",
			"Australia/Eucla"
		]
	},
	"AW": {
		"name": "Aruba",
		"zones": [
			"America/Curacao"
		]
	},
	"AX": {
		"name": "Åland Islands",
		"zones": [
			"Europe/Helsinki"
		]
	},
	"AZ": {
		"name": "Azerbaijan",
		"zones": [
			"Asia/Baku"
		]
	},
	"BA": {
		"name": "Bosnia & Herzegovina",
		"zones": [
			"Europe/Belgrade"
		]
	},
	"BB": {
		"name": "Barbados",
		"zones": [
			"America/Barbados"
		]
	},
	"BD": {
		"name": "Bangladesh",
		"zones": [
			"Asia/Dhaka"
		]
	},
	"BE": {
		"name": "Belgium",
		"zones": [
			"Europe/Brussels"
		]
	},
	"BF": {
		"name": "Burkina Faso",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"BG": {
		"name": "Bulgaria",
		"zones": [
			"Europe/Sofia"
		]
	},
	"BH": {
		"name": "Bahrain",
		"zones": [
			"Asia/Qatar"
		]
	},
	"BI": {
		"name": "Burundi",
		"zones": [
			"Africa/Maputo"
		]
	},
	"BJ": {
		"name": "Benin",
		"zones": [
			"Africa/Lagos"
		]
	},
	"BL": {
		"name": "St Barthelemy",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"BM": {
		"name": "Bermuda",
		"zones": [
			"Atlantic/Bermuda"
		]
	},
	"BN": {
		"name": "Brunei",
		"zones": [
			"Asia/Brunei"
		]
	},
	"BO": {
		"name": "Bolivia",
		"zones": [
			"America/La_Paz"
		]
	},
	"BQ": {
		"name": "Caribbean NL",
		"zones": [
			"America/Curacao"
		]
	},
	"BR": {
		"name": "Brazil",
		"zones": [
			"America/Noronha",
			"America/Belem",
			"America/Fortaleza",
			"America/Recife",
			"America/Araguaina",
			"America/Maceio",
			"America/Bahia",
			"America/Sao_Paulo",
			"America/Campo_Grande",
			"America/Cuiaba",
			"America/Santarem",
			"America/Porto_Velho",
			"America/Boa_Vista",
			"America/Manaus",
			"America/Eirunepe",
			"America/Rio_Branco"
		]
	},
	"BS": {
		"name": "Bahamas",
		"zones": [
			"America/Nassau"
		]
	},
	"BT": {
		"name": "Bhutan",
		"zones": [
			"Asia/Thimphu"
		]
	},
	"BW": {
		"name": "Botswana",
		"zones": [
			"Africa/Maputo"
		]
	},
	"BY": {
		"name": "Belarus",
		"zones": [
			"Europe/Minsk"
		]
	},
	"BZ": {
		"name": "Belize",
		"zones": [
			"America/Belize"
		]
	},
	"CA": {
		"name": "Canada",
		"zones": [
			"America/St_Johns",
			"America/Halifax",
			"America/Glace_Bay",
			"America/Moncton",
			"America/Goose_Bay",
			"America/Blanc-Sablon",
			"America/Toronto",
			"America/Nipigon",
			"America/Thunder_Bay",
			"America/Iqaluit",
			"America/Pangnirtung",
			"America/Atikokan",
			"America/Winnipeg",
			"America/Rainy_River",
			"America/Resolute",
			"America/Rankin_Inlet",
			"America/Regina",
			"America/Swift_Current",
			"America/Edmonton",
			"America/Cambridge_Bay",
			"America/Yellowknife",
			"America/Inuvik",
			"America/Creston",
			"America/Dawson_Creek",
			"America/Fort_Nelson",
			"America/Vancouver",
			"America/Whitehorse",
			"America/Dawson"
		]
	},
	"CC": {
		"name": "Cocos (Keeling) Islands",
		"zones": [
			"Indian/Cocos"
		]
	},
	"CD": {
		"name": "Congo (Dem. Rep.)",
		"zones": [
			"Africa/Maputo",
			"Africa/Lagos"
		]
	},
	"CF": {
		"name": "Central African Rep.",
		"zones": [
			"Africa/Lagos"
		]
	},
	"CG": {
		"name": "Congo (Rep.)",
		"zones": [
			"Africa/Lagos"
		]
	},
	"CH": {
		"name": "Switzerland",
		"zones": [
			"Europe/Zurich"
		]
	},
	"CI": {
		"name": "Côte d'Ivoire",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"CK": {
		"name": "Cook Islands",
		"zones": [
			"Pacific/Rarotonga"
		]
	},
	"CL": {
		"name": "Chile",
		"zones": [
			"America/Santiago",
			"Pacific/Easter"
		]
	},
	"CM": {
		"name": "Cameroon",
		"zones": [
			"Africa/Lagos"
		]
	},
	"CN": {
		"name": "China",
		"zones": [
			"Asia/Shanghai",
			"Asia/Urumqi"
		]
	},
	"CO": {
		"name": "Colombia",
		"zones": [
			"America/Bogota"
		]
	},
	"CR": {
		"name": "Costa Rica",
		"zones": [
			"America/Costa_Rica"
		]
	},
	"CU": {
		"name": "Cuba",
		"zones": [
			"America/Havana"
		]
	},
	"CV": {
		"name": "Cape Verde",
		"zones": [
			"Atlantic/Cape_Verde"
		]
	},
	"CW": {
		"name": "Curacao",
		"zones": [
			"America/Curacao"
		]
	},
	"CX": {
		"name": "Christmas Island",
		"zones": [
			"Indian/Christmas"
		]
	},
	"CY": {
		"name": "Cyprus",
		"zones": [
			"Asia/Nicosia"
		]
	},
	"CZ": {
		"name": "Czech Republic",
		"zones": [
			"Europe/Prague"
		]
	},
	"DE": {
		"name": "Germany",
		"zones": [
			"Europe/Zurich",
			"Europe/Berlin"
		]
	},
	"DJ": {
		"name": "Djibouti",
		"zones": [
			"Africa/Nairobi"
		]
	},
	"DK": {
		"name": "Denmark",
		"zones": [
			"Europe/Copenhagen"
		]
	},
	"DM": {
		"name": "Dominica",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"DO": {
		"name": "Dominican Republic",
		"zones": [
			"America/Santo_Domingo"
		]
	},
	"DZ": {
		"name": "Algeria",
		"zones": [
			"Africa/Algiers"
		]
	},
	"EC": {
		"name": "Ecuador",
		"zones": [
			"America/Guayaquil",
			"Pacific/Galapagos"
		]
	},
	"EE": {
		"name": "Estonia",
		"zones": [
			"Europe/Tallinn"
		]
	},
	"EG": {
		"name": "Egypt",
		"zones": [
			"Africa/Cairo"
		]
	},
	"EH": {
		"name": "Western Sahara",
		"zones": [
			"Africa/El_Aaiun"
		]
	},
	"ER": {
		"name": "Eritrea",
		"zones": [
			"Africa/Nairobi"
		]
	},
	"ES": {
		"name": "Spain",
		"zones": [
			"Europe/Madrid",
			"Africa/Ceuta",
			"Atlantic/Canary"
		]
	},
	"ET": {
		"name": "Ethiopia",
		"zones": [
			"Africa/Nairobi"
		]
	},
	"FI": {
		"name": "Finland",
		"zones": [
			"Europe/Helsinki"
		]
	},
	"FJ": {
		"name": "Fiji",
		"zones": [
			"Pacific/Fiji"
		]
	},
	"FK": {
		"name": "Falkland Islands",
		"zones": [
			"Atlantic/Stanley"
		]
	},
	"FM": {
		"name": "Micronesia",
		"zones": [
			"Pacific/Chuuk",
			"Pacific/Pohnpei",
			"Pacific/Kosrae"
		]
	},
	"FO": {
		"name": "Faroe Islands",
		"zones": [
			"Atlantic/Faroe"
		]
	},
	"FR": {
		"name": "France",
		"zones": [
			"Europe/Paris"
		]
	},
	"GA": {
		"name": "Gabon",
		"zones": [
			"Africa/Lagos"
		]
	},
	"GB": {
		"name": "Britain (UK)",
		"zones": [
			"Europe/London"
		]
	},
	"GD": {
		"name": "Grenada",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"GE": {
		"name": "Georgia",
		"zones": [
			"Asia/Tbilisi"
		]
	},
	"GF": {
		"name": "French Guiana",
		"zones": [
			"America/Cayenne"
		]
	},
	"GG": {
		"name": "Guernsey",
		"zones": [
			"Europe/London"
		]
	},
	"GH": {
		"name": "Ghana",
		"zones": [
			"Africa/Accra"
		]
	},
	"GI": {
		"name": "Gibraltar",
		"zones": [
			"Europe/Gibraltar"
		]
	},
	"GL": {
		"name": "Greenland",
		"zones": [
			"America/Godthab",
			"America/Danmarkshavn",
			"America/Scoresbysund",
			"America/Thule"
		]
	},
	"GM": {
		"name": "Gambia",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"GN": {
		"name": "Guinea",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"GP": {
		"name": "Guadeloupe",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"GQ": {
		"name": "Equatorial Guinea",
		"zones": [
			"Africa/Lagos"
		]
	},
	"GR": {
		"name": "Greece",
		"zones": [
			"Europe/Athens"
		]
	},
	"GS": {
		"name": "South Georgia & the South Sandwich Islands",
		"zones": [
			"Atlantic/South_Georgia"
		]
	},
	"GT": {
		"name": "Guatemala",
		"zones": [
			"America/Guatemala"
		]
	},
	"GU": {
		"name": "Guam",
		"zones": [
			"Pacific/Guam"
		]
	},
	"GW": {
		"name": "Guinea-Bissau",
		"zones": [
			"Africa/Bissau"
		]
	},
	"GY": {
		"name": "Guyana",
		"zones": [
			"America/Guyana"
		]
	},
	"HK": {
		"name": "Hong Kong",
		"zones": [
			"Asia/Hong_Kong"
		]
	},
	"HN": {
		"name": "Honduras",
		"zones": [
			"America/Tegucigalpa"
		]
	},
	"HR": {
		"name": "Croatia",
		"zones": [
			"Europe/Belgrade"
		]
	},
	"HT": {
		"name": "Haiti",
		"zones": [
			"America/Port-au-Prince"
		]
	},
	"HU": {
		"name": "Hungary",
		"zones": [
			"Europe/Budapest"
		]
	},
	"ID": {
		"name": "Indonesia",
		"zones": [
			"Asia/Jakarta",
			"Asia/Pontianak",
			"Asia/Makassar",
			"Asia/Jayapura"
		]
	},
	"IE": {
		"name": "Ireland",
		"zones": [
			"Europe/Dublin"
		]
	},
	"IL": {
		"name": "Israel",
		"zones": [
			"Asia/Jerusalem"
		]
	},
	"IM": {
		"name": "Isle of Man",
		"zones": [
			"Europe/London"
		]
	},
	"IN": {
		"name": "India",
		"zones": [
			"Asia/Kolkata"
		]
	},
	"IO": {
		"name": "British Indian Ocean Territory",
		"zones": [
			"Indian/Chagos"
		]
	},
	"IQ": {
		"name": "Iraq",
		"zones": [
			"Asia/Baghdad"
		]
	},
	"IR": {
		"name": "Iran",
		"zones": [
			"Asia/Tehran"
		]
	},
	"IS": {
		"name": "Iceland",
		"zones": [
			"Atlantic/Reykjavik"
		]
	},
	"IT": {
		"name": "Italy",
		"zones": [
			"Europe/Rome"
		]
	},
	"JE": {
		"name": "Jersey",
		"zones": [
			"Europe/London"
		]
	},
	"JM": {
		"name": "Jamaica",
		"zones": [
			"America/Jamaica"
		]
	},
	"JO": {
		"name": "Jordan",
		"zones": [
			"Asia/Amman"
		]
	},
	"JP": {
		"name": "Japan",
		"zones": [
			"Asia/Tokyo"
		]
	},
	"KE": {
		"name": "Kenya",
		"zones": [
			"Africa/Nairobi"
		]
	},
	"KG": {
		"name": "Kyrgyzstan",
		"zones": [
			"Asia/Bishkek"
		]
	},
	"KH": {
		"name": "Cambodia",
		"zones": [
			"Asia/Bangkok"
		]
	},
	"KI": {
		"name": "Kiribati",
		"zones": [
			"Pacific/Tarawa",
			"Pacific/Enderbury",
			"Pacific/Kiritimati"
		]
	},
	"KM": {
		"name": "Comoros",
		"zones": [
			"Africa/Nairobi"
		]
	},
	"KN": {
		"name": "St Kitts & Nevis",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"KP": {
		"name": "Korea (North)",
		"zones": [
			"Asia/Pyongyang"
		]
	},
	"KR": {
		"name": "Korea (South)",
		"zones": [
			"Asia/Seoul"
		]
	},
	"KW": {
		"name": "Kuwait",
		"zones": [
			"Asia/Riyadh"
		]
	},
	"KY": {
		"name": "Cayman Islands",
		"zones": [
			"America/Panama"
		]
	},
	"KZ": {
		"name": "Kazakhstan",
		"zones": [
			"Asia/Almaty",
			"Asia/Qyzylorda",
			"Asia/Aqtobe",
			"Asia/Aqtau",
			"Asia/Oral"
		]
	},
	"LA": {
		"name": "Laos",
		"zones": [
			"Asia/Bangkok"
		]
	},
	"LB": {
		"name": "Lebanon",
		"zones": [
			"Asia/Beirut"
		]
	},
	"LC": {
		"name": "St Lucia",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"LI": {
		"name": "Liechtenstein",
		"zones": [
			"Europe/Zurich"
		]
	},
	"LK": {
		"name": "Sri Lanka",
		"zones": [
			"Asia/Colombo"
		]
	},
	"LR": {
		"name": "Liberia",
		"zones": [
			"Africa/Monrovia"
		]
	},
	"LS": {
		"name": "Lesotho",
		"zones": [
			"Africa/Johannesburg"
		]
	},
	"LT": {
		"name": "Lithuania",
		"zones": [
			"Europe/Vilnius"
		]
	},
	"LU": {
		"name": "Luxembourg",
		"zones": [
			"Europe/Luxembourg"
		]
	},
	"LV": {
		"name": "Latvia",
		"zones": [
			"Europe/Riga"
		]
	},
	"LY": {
		"name": "Libya",
		"zones": [
			"Africa/Tripoli"
		]
	},
	"MA": {
		"name": "Morocco",
		"zones": [
			"Africa/Casablanca"
		]
	},
	"MC": {
		"name": "Monaco",
		"zones": [
			"Europe/Monaco"
		]
	},
	"MD": {
		"name": "Moldova",
		"zones": [
			"Europe/Chisinau"
		]
	},
	"ME": {
		"name": "Montenegro",
		"zones": [
			"Europe/Belgrade"
		]
	},
	"MF": {
		"name": "St Martin (French)",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"MG": {
		"name": "Madagascar",
		"zones": [
			"Africa/Nairobi"
		]
	},
	"MH": {
		"name": "Marshall Islands",
		"zones": [
			"Pacific/Majuro",
			"Pacific/Kwajalein"
		]
	},
	"MK": {
		"name": "Macedonia",
		"zones": [
			"Europe/Belgrade"
		]
	},
	"ML": {
		"name": "Mali",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"MM": {
		"name": "Myanmar (Burma)",
		"zones": [
			"Asia/Rangoon"
		]
	},
	"MN": {
		"name": "Mongolia",
		"zones": [
			"Asia/Ulaanbaatar",
			"Asia/Hovd",
			"Asia/Choibalsan"
		]
	},
	"MO": {
		"name": "Macau",
		"zones": [
			"Asia/Macau"
		]
	},
	"MP": {
		"name": "Northern Mariana Islands",
		"zones": [
			"Pacific/Guam"
		]
	},
	"MQ": {
		"name": "Martinique",
		"zones": [
			"America/Martinique"
		]
	},
	"MR": {
		"name": "Mauritania",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"MS": {
		"name": "Montserrat",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"MT": {
		"name": "Malta",
		"zones": [
			"Europe/Malta"
		]
	},
	"MU": {
		"name": "Mauritius",
		"zones": [
			"Indian/Mauritius"
		]
	},
	"MV": {
		"name": "Maldives",
		"zones": [
			"Indian/Maldives"
		]
	},
	"MW": {
		"name": "Malawi",
		"zones": [
			"Africa/Maputo"
		]
	},
	"MX": {
		"name": "Mexico",
		"zones": [
			"America/Mexico_City",
			"America/Cancun",
			"America/Merida",
			"America/Monterrey",
			"America/Matamoros",
			"America/Mazatlan",
			"America/Chihuahua",
			"America/Ojinaga",
			"America/Hermosillo",
			"America/Tijuana",
			"America/Bahia_Banderas"
		]
	},
	"MY": {
		"name": "Malaysia",
		"zones": [
			"Asia/Kuala_Lumpur",
			"Asia/Kuching"
		]
	},
	"MZ": {
		"name": "Mozambique",
		"zones": [
			"Africa/Maputo"
		]
	},
	"NA": {
		"name": "Namibia",
		"zones": [
			"Africa/Windhoek"
		]
	},
	"NC": {
		"name": "New Caledonia",
		"zones": [
			"Pacific/Noumea"
		]
	},
	"NE": {
		"name": "Niger",
		"zones": [
			"Africa/Lagos"
		]
	},
	"NF": {
		"name": "Norfolk Island",
		"zones": [
			"Pacific/Norfolk"
		]
	},
	"NG": {
		"name": "Nigeria",
		"zones": [
			"Africa/Lagos"
		]
	},
	"NI": {
		"name": "Nicaragua",
		"zones": [
			"America/Managua"
		]
	},
	"NL": {
		"name": "Netherlands",
		"zones": [
			"Europe/Amsterdam"
		]
	},
	"NO": {
		"name": "Norway",
		"zones": [
			"Europe/Oslo"
		]
	},
	"NP": {
		"name": "Nepal",
		"zones": [
			"Asia/Kathmandu"
		]
	},
	"NR": {
		"name": "Nauru",
		"zones": [
			"Pacific/Nauru"
		]
	},
	"NU": {
		"name": "Niue",
		"zones": [
			"Pacific/Niue"
		]
	},
	"NZ": {
		"name": "New Zealand",
		"zones": [
			"Pacific/Auckland",
			"Pacific/Chatham"
		]
	},
	"OM": {
		"name": "Oman",
		"zones": [
			"Asia/Dubai"
		]
	},
	"PA": {
		"name": "Panama",
		"zones": [
			"America/Panama"
		]
	},
	"PE": {
		"name": "Peru",
		"zones": [
			"America/Lima"
		]
	},
	"PF": {
		"name": "French Polynesia",
		"zones": [
			"Pacific/Tahiti",
			"Pacific/Marquesas",
			"Pacific/Gambier"
		]
	},
	"PG": {
		"name": "Papua New Guinea",
		"zones": [
			"Pacific/Port_Moresby",
			"Pacific/Bougainville"
		]
	},
	"PH": {
		"name": "Philippines",
		"zones": [
			"Asia/Manila"
		]
	},
	"PK": {
		"name": "Pakistan",
		"zones": [
			"Asia/Karachi"
		]
	},
	"PL": {
		"name": "Poland",
		"zones": [
			"Europe/Warsaw"
		]
	},
	"PM": {
		"name": "St Pierre & Miquelon",
		"zones": [
			"America/Miquelon"
		]
	},
	"PN": {
		"name": "Pitcairn",
		"zones": [
			"Pacific/Pitcairn"
		]
	},
	"PR": {
		"name": "Puerto Rico",
		"zones": [
			"America/Puerto_Rico"
		]
	},
	"PS": {
		"name": "Palestine",
		"zones": [
			"Asia/Gaza",
			"Asia/Hebron"
		]
	},
	"PT": {
		"name": "Portugal",
		"zones": [
			"Europe/Lisbon",
			"Atlantic/Madeira",
			"Atlantic/Azores"
		]
	},
	"PW": {
		"name": "Palau",
		"zones": [
			"Pacific/Palau"
		]
	},
	"PY": {
		"name": "Paraguay",
		"zones": [
			"America/Asuncion"
		]
	},
	"QA": {
		"name": "Qatar",
		"zones": [
			"Asia/Qatar"
		]
	},
	"RE": {
		"name": "Réunion",
		"zones": [
			"Indian/Reunion"
		]
	},
	"RO": {
		"name": "Romania",
		"zones": [
			"Europe/Bucharest"
		]
	},
	"RS": {
		"name": "Serbia",
		"zones": [
			"Europe/Belgrade"
		]
	},
	"RU": {
		"name": "Russia",
		"zones": [
			"Europe/Kaliningrad",
			"Europe/Moscow",
			"Europe/Simferopol",
			"Europe/Volgograd",
			"Europe/Kirov",
			"Europe/Astrakhan",
			"Europe/Samara",
			"Europe/Ulyanovsk",
			"Asia/Yekaterinburg",
			"Asia/Omsk",
			"Asia/Novosibirsk",
			"Asia/Barnaul",
			"Asia/Tomsk",
			"Asia/Novokuznetsk",
			"Asia/Krasnoyarsk",
			"Asia/Irkutsk",
			"Asia/Chita",
			"Asia/Yakutsk",
			"Asia/Khandyga",
			"Asia/Vladivostok",
			"Asia/Ust-Nera",
			"Asia/Magadan",
			"Asia/Sakhalin",
			"Asia/Srednekolymsk",
			"Asia/Kamchatka",
			"Asia/Anadyr"
		]
	},
	"RW": {
		"name": "Rwanda",
		"zones": [
			"Africa/Maputo"
		]
	},
	"SA": {
		"name": "Saudi Arabia",
		"zones": [
			"Asia/Riyadh"
		]
	},
	"SB": {
		"name": "Solomon Islands",
		"zones": [
			"Pacific/Guadalcanal"
		]
	},
	"SC": {
		"name": "Seychelles",
		"zones": [
			"Indian/Mahe"
		]
	},
	"SD": {
		"name": "Sudan",
		"zones": [
			"Africa/Khartoum"
		]
	},
	"SE": {
		"name": "Sweden",
		"zones": [
			"Europe/Stockholm"
		]
	},
	"SG": {
		"name": "Singapore",
		"zones": [
			"Asia/Singapore"
		]
	},
	"SH": {
		"name": "St Helena",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"SI": {
		"name": "Slovenia",
		"zones": [
			"Europe/Belgrade"
		]
	},
	"SJ": {
		"name": "Svalbard & Jan Mayen",
		"zones": [
			"Europe/Oslo"
		]
	},
	"SK": {
		"name": "Slovakia",
		"zones": [
			"Europe/Prague"
		]
	},
	"SL": {
		"name": "Sierra Leone",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"SM": {
		"name": "San Marino",
		"zones": [
			"Europe/Rome"
		]
	},
	"SN": {
		"name": "Senegal",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"SO": {
		"name": "Somalia",
		"zones": [
			"Africa/Nairobi"
		]
	},
	"SR": {
		"name": "Suriname",
		"zones": [
			"America/Paramaribo"
		]
	},
	"SS": {
		"name": "South Sudan",
		"zones": [
			"Africa/Khartoum"
		]
	},
	"ST": {
		"name": "Sao Tome & Principe",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"SV": {
		"name": "El Salvador",
		"zones": [
			"America/El_Salvador"
		]
	},
	"SX": {
		"name": "St Maarten (Dutch)",
		"zones": [
			"America/Curacao"
		]
	},
	"SY": {
		"name": "Syria",
		"zones": [
			"Asia/Damascus"
		]
	},
	"SZ": {
		"name": "Swaziland",
		"zones": [
			"Africa/Johannesburg"
		]
	},
	"TC": {
		"name": "Turks & Caicos Is",
		"zones": [
			"America/Grand_Turk"
		]
	},
	"TD": {
		"name": "Chad",
		"zones": [
			"Africa/Ndjamena"
		]
	},
	"TF": {
		"name": "French Southern & Antarctic Lands",
		"zones": [
			"Indian/Reunion",
			"Indian/Kerguelen"
		]
	},
	"TG": {
		"name": "Togo",
		"zones": [
			"Africa/Abidjan"
		]
	},
	"TH": {
		"name": "Thailand",
		"zones": [
			"Asia/Bangkok"
		]
	},
	"TJ": {
		"name": "Tajikistan",
		"zones": [
			"Asia/Dushanbe"
		]
	},
	"TK": {
		"name": "Tokelau",
		"zones": [
			"Pacific/Fakaofo"
		]
	},
	"TL": {
		"name": "East Timor",
		"zones": [
			"Asia/Dili"
		]
	},
	"TM": {
		"name": "Turkmenistan",
		"zones": [
			"Asia/Ashgabat"
		]
	},
	"TN": {
		"name": "Tunisia",
		"zones": [
			"Africa/Tunis"
		]
	},
	"TO": {
		"name": "Tonga",
		"zones": [
			"Pacific/Tongatapu"
		]
	},
	"TR": {
		"name": "Turkey",
		"zones": [
			"Europe/Istanbul"
		]
	},
	"TT": {
		"name": "Trinidad & Tobago",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"TV": {
		"name": "Tuvalu",
		"zones": [
			"Pacific/Funafuti"
		]
	},
	"TW": {
		"name": "Taiwan",
		"zones": [
			"Asia/Taipei"
		]
	},
	"TZ": {
		"name": "Tanzania",
		"zones": [
			"Africa/Nairobi"
		]
	},
	"UA": {
		"name": "Ukraine",
		"zones": [
			"Europe/Kiev",
			"Europe/Uzhgorod",
			"Europe/Zaporozhye"
		]
	},
	"UG": {
		"name": "Uganda",
		"zones": [
			"Africa/Nairobi"
		]
	},
	"UM": {
		"name": "US minor outlying islands",
		"zones": [
			"Pacific/Pago_Pago",
			"Pacific/Wake",
			"Pacific/Honolulu"
		]
	},
	"US": {
		"name": "United States",
		"zones": [
			"America/New_York",
			"America/Detroit",
			"America/Kentucky/Louisville",
			"America/Kentucky/Monticello",
			"America/Indiana/Indianapolis",
			"America/Indiana/Vincennes",
			"America/Indiana/Winamac",
			"America/Indiana/Marengo",
			"America/Indiana/Petersburg",
			"America/Indiana/Vevay",
			"America/Chicago",
			"America/Indiana/Tell_City",
			"America/Indiana/Knox",
			"America/Menominee",
			"America/North_Dakota/Center",
			"America/North_Dakota/New_Salem",
			"America/North_Dakota/Beulah",
			"America/Denver",
			"America/Boise",
			"America/Phoenix",
			"America/Los_Angeles",
			"America/Anchorage",
			"America/Juneau",
			"America/Sitka",
			"America/Metlakatla",
			"America/Yakutat",
			"America/Nome",
			"America/Adak",
			"Pacific/Honolulu"
		]
	},
	"UY": {
		"name": "Uruguay",
		"zones": [
			"America/Montevideo"
		]
	},
	"UZ": {
		"name": "Uzbekistan",
		"zones": [
			"Asia/Samarkand",
			"Asia/Tashkent"
		]
	},
	"VA": {
		"name": "Vatican City",
		"zones": [
			"Europe/Rome"
		]
	},
	"VC": {
		"name": "St Vincent",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"VE": {
		"name": "Venezuela",
		"zones": [
			"America/Caracas"
		]
	},
	"VG": {
		"name": "Virgin Islands (UK)",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"VI": {
		"name": "Virgin Islands (US)",
		"zones": [
			"America/Port_of_Spain"
		]
	},
	"VN": {
		"name": "Vietnam",
		"zones": [
			"Asia/Bangkok",
			"Asia/Ho_Chi_Minh"
		]
	},
	"VU": {
		"name": "Vanuatu",
		"zones": [
			"Pacific/Efate"
		]
	},
	"WF": {
		"name": "Wallis & Futuna",
		"zones": [
			"Pacific/Wallis"
		]
	},
	"WS": {
		"name": "Samoa (western)",
		"zones": [
			"Pacific/Apia"
		]
	},
	"YE": {
		"name": "Yemen",
		"zones": [
			"Asia/Riyadh"
		]
	},
	"YT": {
		"name": "Mayotte",
		"zones": [
			"Africa/Nairobi"
		]
	},
	"ZA": {
		"name": "South Africa",
		"zones": [
			"Africa/Johannesburg"
		]
	},
	"ZM": {
		"name": "Zambia",
		"zones": [
			"Africa/Maputo"
		]
	},
	"ZW": {
		"name": "Zimbabwe",
		"zones": [
			"Africa/Maputo"
		]
	}
};
