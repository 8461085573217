import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {PageHeading} from '../widgets/headings.js';
import './thankYouPage.css';
import {GRAY_HELP} from '../colors';
import {AUTOLOGIN_URL, CHECK_TRIAL_STATUS_URL} from '../urls';
import {withRouter} from 'react-router';

const styles = {};

const _WorkingPage = ({jobId, history}) => {

  useEffect(() => {
    (async () => {
      try {
        let data;
        let count = 0;
        do {
          const res = await fetch(CHECK_TRIAL_STATUS_URL({jobId}));
          data = await res.json();
        } while (data.status === "running" || count >= 30);
        if (data.options && data.options.autologin && data.options.rid) {
          window.location.href = AUTOLOGIN_URL({realmId: data.options.rid, autologin: data.options.autologin});
        } else {
          history.push({pathname: window.contextPath + 'autologinFailed'});
        }
      } catch (e) {
        console.log("Failed retrieving trial status: ", e);
        history.push({pathname: window.contextPath + 'autologinFailed'});
      }
    })();
  }, [jobId]);

  return (
      <div className="contres" style={ styles.container }>
        <span style={ styles.filler } />
        <PageHeading>{ `Creating your academy, please wait...` }</PageHeading>
            <img style={styles.animatedImg} src={require('../img/working-transparent.gif')} alt={'loading...'}/>
        <p style={ styles.info }>
          When the realm is ready we will automatically redirect you to your new academy. We will also email you a
          link to the academy that you should use to log in later on.
        </p>
        <span style={ styles.filler } />
      </div>
  );
};

_WorkingPage.propTypes = {
  jobId: PropTypes.string.isRequired,
};

export const WorkingPage = withRouter(_WorkingPage);

Object.assign( styles, {
  container: {
    maxWidth: '100%',
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  info: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight:600,
    marginTop: '2rem',
    color: GRAY_HELP
  },

  animatedImg: {
    width: '100%',
    textAlign: 'center',
    maxHeight: '100%'
  },

  filler: {
    flex: '1 0 0',
  },

  envelope: {
    position: 'relative',
    overflow: 'hidden'
  },

  thankYou: {
    width: '100%',
  },
  } );
