import React from 'react';
import radium from 'radium';
import { childrenPropType } from '../propTypes.js';
import './formRow.css';

const styles = {};

const FormRow = ( props ) => (
  <div className="form-row" style={ [ styles.container, props.style ] }>
    {
      props.children
    }
  </div>
);

FormRow.propTypes = {
  children: childrenPropType
};

Object.assign( styles, {
  container: {
    width: '100%',
    '@media (max-width: 500px)': {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
} );

export default radium( FormRow );
