import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import './formItem.css';

const styles = {};

class FormItem extends Component {

  static propTypes = {
    horizontal: PropTypes.bool,
    style: PropTypes.object,
  };

  constructor( props ) {
    super( props );

    this.state = {

    };
  }

  render() {
    return (
      <div
        className={ `form-item ${ this.props.horizontal ? 'form-item--horizontal' : '' }` }
        style={ [ styles.container, this.props.style ] }
      >
        {
          Children.map( this.props.children, ( element ) => (
            <div className="form-item-content" style={ styles.formItemContent }>
              {
                cloneElement( element, element.props )
              }
            </div>
          ) )
        }
      </div>
    );
  }

}

export default radium( FormItem );

Object.assign( styles, {
  container: {
    verticalAlign: 'middle',
    width: 'auto',
    flex: 1,
    '@media (max-width: 500px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },

  formItemContent: {
    flex: '1 0 0',
    alignItems: 'center',
    '@media (max-width: 500px)': {
      flex: 'initial'
    },
  }
} );
