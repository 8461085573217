import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import { childrenPropType } from '../propTypes.js';


class Button extends Component {

  static propTypes = {
    children: childrenPropType,
    style: PropTypes.object,

    onClick: PropTypes.func,
  };

  constructor( props ) {
    super( props );

    this.state = {};
  }

  handleClick = ( event ) => {
    if ( this.props.onClick ) {
      this.props.onClick( event );
    }
  }

  render() {
    return (
      <button
        style={ this.props.style }
        disabled={ this.props.disabled }
        onClick={ this.handleClick }
      >
        {
          this.props.children
        }
      </button>
    );
  }
}

export default radium( Button );
