import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';
import InputField from './InputField';
import './textInputField.css';
import { GRAY_HELP } from '../colors'

const styles = {};

class TextInputField extends Component {

  setRef = ( element ) => {
    this.inputField = element;
  }

  render() {
    const {
      containerStyle,
      style,
      children,
    } = this.props;

    return (
      <div className="text-input-field-container" style={ [ styles.container, containerStyle ] }>
        <div className="text-input-field-wrapper">
          <InputField
            { ...this.props }
            ref={ this.setRef }
            className="text-input-field"
            style={ [ styles.input, Children.count( children ) && styles.withChildren, style ] }
          />
        </div>
        {
          Children.map( children, ( element ) => (
          element ? (
            <div className="text-input-field-child">
              {
                cloneElement( element, element.props )
              }
            </div>
          ) : null
          ) )
        }
      </div>
    );
  }
}

TextInputField.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any,
  type: PropTypes.string,
  style: PropTypes.object,
  containerStyle: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default radium( TextInputField );

Object.assign( styles, {
  container: {
    padding: '0.5rem  0.5rem 0.5rem 1rem',
    fontSize: '1rem',
    //borderRadius: 6,
    border: 'solid 1px #d9d9d9',
    outline: 0,
    backgroundColor: 'white',
  },

  input: {
    padding: 0,
    border: 0,
    outline: 0,
    fontSize: '1rem',
    width: '100%',
    backgroundColor: 'white',
    color: GRAY_HELP
  },

  withChildren: {
    marginRight: '.5rem',
  },
} );
