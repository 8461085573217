import React from 'react';
import radium from 'radium';
import { childrenPropType } from '../propTypes.js';
import { Button } from '../widgets';
import {BLUE, BLUE_HOVER, GREEN, GREEN_HOVER, WHITE} from '../colors'

const styles = {};

const SlabButton = radium ( ( props ) => (
  <Button key="button" { ...props } style={ [ styles.container, props.disabled && styles.disabled ] }>
    <span style={ styles.label }>
      { props.children }
    </span>
  </Button>
) );

SlabButton.propTypes = {
  children: childrenPropType,
};

export default SlabButton;

Object.assign( styles, {
  container: {
    display: 'block',
    width: '100%',
    padding: '0.7rem 0',
    border: 0,
    outline: 0,
    borderRadius: '10px',
    position: 'relative',
    backgroundColor: BLUE,
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out',

    ':hover': {
      backgroundColor: BLUE_HOVER,
    },
  },

  disabled: {
    opacity: .5,
    cursor: 'not-allowed'
  },

  label: {
    fontSize: '1rem',
    color: WHITE,
    fontWeight: 600,
  }
} );
