import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PageHeading } from '../widgets/headings.js';
import { Disclaimer } from '../widgets';
import thankYouSrc from '../img/Learnifier Mailbird 2024.webp';

import './thankYouPage.css';
import { GRAY_HELP } from '../colors'

const styles = {};

export default class ThankYouPage extends Component {

  static propTypes = {
    firstName: PropTypes.string.isRequired,
  };

  constructor( props ) {
    super( props );

    this.state = {

    };
  }

  render() {
    return (
      <div style={ styles.container }>
        <span style={ styles.filler } />
        <PageHeading>{ `Thanks ${ this.props.firstName }!` }</PageHeading>
        <div style={ styles.envelope }>
          <div>
            <img
              style={ styles.thankYou }
              src={ thankYouSrc }
              role="presentation"
            />
          </div>
        </div>
        <p style={ styles.info }>
          Please, check your inbox. We have sent an email to <span id={"signupEmail"}>{ this.props.email }</span> with your login details.
        </p>
        <Disclaimer>
          { 'If you didn’t get an email, please check your spam folder and then contact our ' }
          <a href="mailto:support@learnifier.com">support team</a>.
        </Disclaimer>
        <span style={ styles.filler } />
      </div>
    );
  }

}

Object.assign( styles, {
  container: {
    height: '100%',
    minHeight: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },

  info: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight:600,
    marginTop: '2rem',
    color: GRAY_HELP
  },

  filler: {
    flex: '1 0 0',
  },

  envelope: {
    position: 'relative',
    overflow: 'hidden'
  },

  thankYou: {
    width: '100%',
  },
} );
