import React from 'react';
import radium from 'radium';

import { childrenPropType } from '../propTypes.js';
import { GRAY_MEDIUM_3 } from '../colors'

const styles = {};

let PageHeading = ( props ) => (
  <h1 style={ [ styles.pageHeading, props.style ] }>
    { props.children }
  </h1>
);

PageHeading.propTypes = {
  children: childrenPropType,
};

let PageSubHeading = ( props ) => (
  <h2 style={ [ styles.pageHeading, styles.pageSubHeading, props.style ] }>
    { props.children }
  </h2>
);

PageSubHeading.propTypes = {
  children: childrenPropType,
};

Object.assign( styles, {
  pageHeading: {
    flex: 1,
    textAlign: 'center',
    fontSize: '35px',
    color: GRAY_MEDIUM_3,
    margin: '0 40px 2.2rem',

    '@media (max-width: 500px)': {
      fontSize: '1.7rem',
    },
  },

  pageSubHeading: {
    fontSize: '1rem',
    margin: '0 0 1rem 0',

    '@media (max-width: 500px)': {
      fontSize: '0.9rem'
    },
  },
} );

PageHeading = radium( PageHeading );
PageSubHeading = radium( PageSubHeading );

export {
  PageHeading,
  PageSubHeading,
};
