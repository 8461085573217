import React from 'react';
import radium from 'radium';
import { GRAY_HELP } from '../colors'

const styles = {
  container: {
    textAlign: 'center',
    fontSize: '0.8rem',
    color: GRAY_HELP
  },
};

export default radium( ( props ) => (
  <p style={ [ styles.container, props.style ] }>
    { props.children }
  </p>
) );
