import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import PasswordValidator from 'password-validator';

import { FormContainer, FormItem, FormRow, SlabButton } from '../components';
import { PageHeading, PageSubHeading, TextInputField, Label, Icon } from '../widgets';
import { CHECK_EMAIL_URL } from '../urls.js';
import { POST } from '../fetchOptions.js';

import eyeSrc from '../img/eye.svg';
import checkmarkSrc from '../img/checkmark-green.svg';
import crossSrc from '../img/cross-red.svg';
import { GRAY_HELP } from '../colors'

const styles = {};
const emailRe = /^[^@]+@[^@]+/;
const passwordValidator = new PasswordValidator();
passwordValidator
.is().min(6)
.has().uppercase()
.has().lowercase()
.has().digits()
.has().not().spaces();

export default class PersonalDetailsPage extends Component {

  static propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    onNext: PropTypes.func.isRequired,
  };

  constructor( props ) {
    super( props );

    this.state = {
      isPasswordVisible: false,
      firstName: props.firstName || '',
      lastName: props.lastName || '',
      email: props.email || '',
      password: props.password || '',
      message:'',
      validated: {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        password: undefined,
      },
    };
  }

  componentDidMount() {
    if(this.state.firstName) {
      this.validateFirstName(this.state.firstName);
    }
    if(this.state.lastName) {
      this.validateLastName(this.state.lastName);
    }
    if(this.state.email) {
      this.validateEmail(this.state.email);
    }
    if(this.state.password) {
      this.validatePassword(this.state.password);
    }
  };

  setPasswordRef = ( element ) => {
    this.passwordInput = element;
  }

  isValid() {
    return Object.keys( this.state.validated ).filter( k => !this.state.validated[ k ] ).length === 0;
  }

  validateEmail( email ) {
    if ( this.emailTimer ) {
      clearTimeout( this.emailTimer );
    }
    this.emailTimer = setTimeout( () => {
      clearTimeout( this.emailTimer );
      this.emailTimer = null;
      fetch(

        CHECK_EMAIL_URL(),
        {
          ...POST,
          body: JSON.stringify( { email } ),
        },
      )
      .then( ( response ) => {
        if ( !response.ok ) {
          return Promise.reject();
        }
        return response;
      } )
      .then( ( response ) => response.json() )
      .then( ( data ) => {
          this.setState( (prevState) => ({ validated: { ...prevState.validated, email: data.result}, message:data.message }) )
        }
      );
    }, 200 );
  }

  validatePassword( password ) {
    this.setState( (prevState) =>  ( { validated: {
        ...prevState.validated,
        password: password ? passwordValidator.validate( password ) : undefined,
      } } ) );
  }

  validateFirstName( firstName ) {
    this.setState( (prevState) => ( {
      validated: {
        ...prevState.validated,
        firstName: firstName && firstName.length > 0
      }
    } ) );
  }

  validateLastName( lastName ) {
    this.setState( (prevState) => ( { validated: {
        ...prevState.validated,
        lastName: lastName && lastName.length > 0
      } } ) );
  }

  renderValidationIcon( value ) {
    if ( value === undefined ) {
      return null;
    }

    return (
      <Icon src={ value ? checkmarkSrc : crossSrc } />
    )
  }

  handleEmailBlur = ( email ) => {
    this.validateEmail( email );
  }

  handleToggleShowPassword = () => {
    this.setState( { isPasswordVisible: ! this.state.isPasswordVisible }, () => {
      const e = findDOMNode( this.passwordInput ).getElementsByTagName( 'input' )[0];
      if ( e ) {
        e.focus();
      }
    } );
  }

  handlePasswordChange = ( password ) => {
    this.setState( { password } );
    this.validatePassword( password );
  }

  handleFirstNameChange = ( firstName ) => {
    this.setState( { firstName } );
    this.validateFirstName( firstName );
  }

  handleLastNameChange = ( lastName ) => {
    this.setState( { lastName } );
    this.validateLastName( lastName );
  }

  handleEmailChange = ( email ) => {
    this.setState( { email } );
    if ( emailRe.test( email ) ) {
      this.validateEmail( email );
    } else {
      this.setState( { validated: { ...this.state.validated, email: undefined } } );
    }
  }

  handleNext = () => {
    const {
      firstName,
      lastName,
      email,
      password,
    } = this.state;

    this.props.onNext( {
      firstName,
      lastName,
      email,
      password,
    } );
  }

  render() {
    const buttonDisabled = !this.isValid();
    const { validated } = this.state;
    return (
      <div style={ styles.container }>
        <PageHeading>Your own Learning Academy. Minutes away.</PageHeading>
        <PageSubHeading>
          We ask for personal information to set up your account.
        </PageSubHeading>
        <FormContainer>
          <FormContainer>

            <FormRow>
              <FormItem>
                <Label>First name</Label>
                <TextInputField
                  value={ this.state.firstName }
                  onChange={ this.handleFirstNameChange }
                  name="fname"
                  autoComplete="given-name"
                >
                  {
                    this.renderValidationIcon(validated.firstName)
                  }
                </TextInputField>
              </FormItem>
              <FormItem>
                <Label>Last name</Label>
                <TextInputField
                  value={ this.state.lastName }
                  onChange={ this.handleLastNameChange }
                  name="lname"
                  autoComplete="family-name"
                >
                  {
                    this.renderValidationIcon( validated.lastName )
                  }
                </TextInputField>
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem>
                <Label>Work email</Label>
                <TextInputField
                  value={ this.state.email }
                  type="email"
                  onChange={ this.handleEmailChange }
                  onBlur={ this.handleEmailBlur }
                  name="email"
                  autoComplete="email"
                >
                  {
                    this.renderValidationIcon( validated.email )
                  }
                </TextInputField>
                <span style={[{marginTop:'5px'}, styles.passwordInfo]}>{this.state.message}</span>
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem>
                <Label>Password</Label>
                <TextInputField
                  ref={ this.setPasswordRef }
                  type={ this.state.isPasswordVisible ? 'text' : 'password' }
                  value={ this.state.password }
                  onChange={ this.handlePasswordChange }
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck={ false }
                >
                  {
                    this.renderValidationIcon( validated.password )
                  }
                  <Icon src={ eyeSrc } onClick={ this.handleToggleShowPassword }/>
                </TextInputField>
              </FormItem>
              <FormItem style={ styles.passwordInfoPadding }>
                <span style={ styles.passwordInfo }>- must be at least 6 characters</span>
                <span style={ styles.passwordInfo }>- must include letters in mixed case and numbers</span>
              </FormItem>
            </FormRow>

          </FormContainer>
          <FormRow style={ styles.buttonRow }>
            <SlabButton disabled={ buttonDisabled } onClick={ this.handleNext }>NEXT STEP</SlabButton>
          </FormRow>
        </FormContainer>
        <p style={ styles.disclaimer }>
          { 'No credit card required \u2022 We never share emails with third parties \u2022 We will contact you via email during the trial'}
        </p>
      </div>
    );
  }

}

Object.assign( styles, {
  container: {
    height: '100%',
    minHeight: '100%',
  },

  heading: {
    padding: '0 10%'
  },

  noPadding: {
    padding: 0,
  },

  passwordInfo: {
    display: 'block',
    fontSize: '9px',
    color: GRAY_HELP
  },

  passwordInfoPadding: {
    paddingTop: '1.9rem',

    '@media (max-width: 500px)': {
      paddingTop: '0.1rem',
      fontSize: '.9rem'
    },
  },

  icon: {
    display: 'block',
  },

  buttonRow: {
    marginTop: '1.7rem'
  },

  disclaimer: {
    textAlign: 'center',
    fontSize: '10px',
    color: GRAY_HELP
  }
} );
