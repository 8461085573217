import React, { Component } from 'react';
import { PageHeading } from '../../widgets/headings.js';

const styles = {};

export default class RealmTrialEnded extends Component {

  constructor( props ) {
    super( props );

    this.state = {

    };
  }

  render() {
    return (
      <div style={ styles.container }>
        <span style={ styles.filler } />
        <PageHeading>Time flies</PageHeading>
        <p style={ styles.info }>
          The free trial has expired, and it is no longer possible to create, administer or view the course that you are trying to access.
        </p>
        <p style={ styles.info }>
          Learnifier is a modern, easy to use tool that lets you create, administrate and distribute digital courses for both e-learning and Blended Learning. All courses are immediately available for participants on computers, tablets and smartphones.
        </p>
        <p style={ styles.info }><b>Course participants</b></p>
        <p style={ styles.info }>
          We hope you have enjoyed the course. You are welcome to visit <a href="http://www.learnifier.com">www.learnifier.com</a> where you will find more information about Learnifier. You can also sign up for a free trial so that you can experience how easy and fun it is to build courses in Learnifier.
        </p>
        <p style={ styles.info }><b>Course administrator</b></p>
        <p style={ styles.info }>
          We hope you have experienced how fast and easy it is to build great courses with Learnifier. Please contact <a href="mailto:sales@learnifier.com">sales@learnifier.com</a> to learn more about our start package and how to get your full version av Learnifier.
        </p>
        <p style={ styles.info }>
          However, we know that it is easy to run out of time when your schedule is full. Please send an email to <a href="mailto:sales@learnifier.com">sales@learnifier.com</a> if you think you need an extended free trial.
        </p>
        <p style={ styles.info }>
          Best regards, The Learnifier Team
        </p>
        <span style={ styles.filler } />
      </div>
      );
    }

  }

  Object.assign( styles, {
    container: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },

    info: {
      textAlign: 'center',
      fontSize: '0.8rem',
      marginTop: '0.5rem',
    },

    filler: {
      flex: '1 0 0',
    }
  } );
