import React from 'react';
import Select from './Select.js';

const styles = {};

export default ( props ) => (
  <Select { ...props } style={ styles.container } />
);

Object.assign( styles, {
  container: {
    fontSize: '1.2rem',
    display: 'block',
    width: '100%',
  },
} );
