import React, { Component } from 'react';
import PropTypes from 'prop-types';
import radium from 'radium';

class InputField extends Component {

  static propTypes = {
    name: PropTypes.any,
    value: PropTypes.any,
    style: PropTypes.object,
    className: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
    autoCorrect: PropTypes.string,
    autoCapitalize: PropTypes.string,
    spellCheck: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    type: 'text',
    value: '',
  };

  constructor( props ) {
    super( props );

    this.state = {
      value: null,
    };
  }

  componentWillReceiveProps( nextProps ) {
    this.setState( {
      value: null,
    } );
  }

  handleChange = ( event ) => {
    if ( this.props.onChange ) {
      this.props.onChange( event.target.value, event );
    } else {
      this.setState( {
        value: event.target.value,
      } );
    }
  }

  handleBlur = ( event ) => {
    if ( this.props.onBlur ) {
      this.props.onBlur( event.target.value, event );
    }
  }

  render() {
    return (
      <input
        name={ this.props.name }
        className={ this.props.className }
        type={ this.props.type }
        style={ this.props.style }
        placeholder={ this.props.placeholder }
        value={ this.state.value !== null ? this.state.value : this.props.value }
        autoComplete={ this.props.autoComplete }
        autoCorrect={ this.props.autoCorrect }
        autoCapitalize={ this.props.autoCapitalize }
        spellCheck={ this.props.spellCheck }
        onChange={ this.handleChange }
        onBlur={ this.handleBlur }
      />
    );
  }
}

export default radium( InputField );
