import React from 'react';

const styles = {
  container: {
    display: 'table',
    width: '100%',
    height: '100%',
  },

  content: {
    display: 'table-cell',
    verticalAlign: 'middle',
    textAlign: 'center',
  },
}

export default ( props ) => (
  <div style={ styles.container }>
    <div style={ styles.content }>
      { props.children }
    </div>
  </div>
);
