import React, {useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {withRouter} from 'react-router';

import {StyleRoot} from 'radium';
import moment from 'moment-timezone';

import PersonalDetailsPage from './pages/PersonalDetailsPage.js';
import RealmDetailsPage from './pages/RealmDetailsPage.js';
import ThankYouPage from './pages/ThankYouPage.js';
import {YELLOW_LIGHT} from './colors.js';
import {POST} from './fetchOptions.js';
import learnifierLogoSrc from './img/learnifier-2023.svg';
import backgroundImage from './img/Learnifier_Site_Backgrounds_2024_V2.webp';
import isoCountries from './isocountries.js';
import {CREATE_TRIAL_URL} from './urls';
import {WorkingPage} from './pages/WorkingPage';
import {AutologinFailed} from './pages/AutologinFailed';
import {InvalidToken} from './pages/InvalidToken';
import {realmConfig} from '@learnifier/jslib-utils';

const styles = {};
const langRe = /^([a-z]{2})(-[a-z]{2})$/i;

function _App ({history}) {

  const timezone = moment.tz.guess();
  let country = Object.keys( isoCountries )
  .find( ( key ) => isoCountries[ key ].zones.indexOf( timezone ) >= 0 ) || 'US';

  const langMatch = ( navigator.language || navigator.userLanguage ).match( langRe );
  let language = langMatch ? langMatch[ 1 ] : 'en';

  const [setupValues, setSetupValues] = useState({language, timezone, country});
  const [personalDetailsDone, setPersonalDetailsDone] = useState(false);
  const [realmDetailsDone, setRealmDetailsDone] = useState(false);

  const handleNextStep = ( newSetupValues ) => {
    setSetupValues({
      ...setupValues,
      ...newSetupValues,
    });
    setPersonalDetailsDone(true);
    history.push({pathname: window.contextPath + 'details'});
  };

  const handleCreateRealm = ( newSetupValues ) => {
    const mergedSetupValues = {
      ...setupValues,
      ...newSetupValues
    };
    setSetupValues(mergedSetupValues);
    setRealmDetailsDone(true);
    createRealm(mergedSetupValues);
  };

  function createRealm( { firstName, lastName, email, password, company, domain, country, language, timezone } ) {

    const options = Object.assign( {}, POST, {
      body: JSON.stringify( {
        firstName, lastName, email, password, company, domain, country, language, timezone
      } )
    } );
    fetch( CREATE_TRIAL_URL(), options )
    .then( ( response ) => response.ok ? response.json() : Promise.reject() )
    .then( ( data ) => {
      if (data.userIdentity) {
        if (window.mixpanel) {
          if (window.mixpanel.get_distinct_id) {
            window.mixpanel.identify(data.userIdentity);
            window.mixpanel.alias(window.mixpanel.get_distinct_id(), data.userIdentity);
          }
          window.mixpanel.track("Signup Done");
        }
      }

      const autoLogin = realmConfig.get('selfservice.autologin');
      if(!autoLogin || autoLogin === "false") {
        history.push({pathname: window.contextPath + 'done'});
      } else {
        history.push({pathname: window.contextPath + 'working'}, {jobId: data.jobId});
      }
    } )
    .catch( () => {
      if (window.mixpanel) {
        window.mixpanel.track("Signup Failed");
      }
      alert(`We're very sorry! Your academy could not be created at this time.`);
    });
  }

  return (
    <StyleRoot style={ styles.container }>
      <div style={ styles.logoContainer }>
        <img
          src={ learnifierLogoSrc }
          style={ styles.logo }
          role="presentation"
        />
      </div>

      <div style={ styles.pageContainer }>
        <Switch>
          <Route path={window.contextPath + "details"} render={() =>
            personalDetailsDone ?
              <RealmDetailsPage
                company={setupValues.company}
                domain={setupValues.domain}
                country={setupValues.country}
                language={setupValues.language}
                timezone={setupValues.timezone}
                onNext={handleCreateRealm}
              />
              :
              <Redirect to={window.contextPath}/>
          } />
          <Route path={window.contextPath + "working"} render={({location}) => {
            const jobId = location.state && location.state.jobId && location.state.jobId.id;
            if(jobId) {
              return (
                realmDetailsDone ?
                  <WorkingPage
                    jobId={jobId}
                  />
                  :
                  <Redirect to={window.contextPath}/>
              );
            } else {
              // No jobId set, probably an old bookmark or someone writing their own urls.
              history.push("/"); // Is this ok to do in a render?
              return null;
            }
          }
          }/>
          <Route path={window.contextPath + "autologinFailed"} render={() =>
            <AutologinFailed />
          }/>
          <Route path={window.contextPath + "invalidToken"} render={() =>
              <InvalidToken />
          }/>
          <Route path={window.contextPath + "done"} render={() =>
            realmDetailsDone ?
              <ThankYouPage
                firstName={setupValues.firstName}
                email={setupValues.email}
              />
              :
              <Redirect to={window.contextPath}/>
          }/>
          <Route path={window.contextPath} render={() =>
            <PersonalDetailsPage
              firstName={ setupValues.firstName }
              lastName={ setupValues.lastName }
              email={ setupValues.email }
              password={ setupValues.password }
              onNext={ handleNextStep }
            />
          } />
        </Switch>
      </div>
    </StyleRoot>
  );
}

Object.assign( styles, {
  container: {
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
    backgroundColor: YELLOW_LIGHT,
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    padding: '2rem',

    '@media (max-width: 500px)': {
      height: 'auto',
      padding: '2rem 1rem',
    }
  },

  pageContainer: {
    maxWidth: '40rem',
    minWidth: 'initial',
    margin: 'auto',
    alignSelf:'center',
  },

  logoContainer: {
    textAlign: 'left',
    '@media (max-width: 500px)': {
      textAlign: 'center',
      marginBottom: '2rem'
    }
  },

  logo: {
    width: '192px',
    marginBottom: '2rem',
  },
} );

export const App = withRouter(_App);
