import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormContainer, FormItem, FormRow, SlabButton, Centered } from '../components';
import { PageHeading, PageSubHeading, TextInputField, Label, Icon, Disclaimer } from '../widgets';
import { CHECK_DOMAIN_URL } from '../urls.js';
import { POST } from '../fetchOptions.js';


import checkmarkSrc from '../img/checkmark-green.svg';
import crossSrc from '../img/cross-red.svg';

const styles = {};

function spinalCase( str ) {
  return str.replace( /[_\s]+(?=[a-zA-Z])/g, '-' ).toLowerCase();
}

export default class RealmDetailsPage extends Component {

  static propTypes = {
    company: PropTypes.string,
    domain: PropTypes.string,
    country: PropTypes.string,
    language: PropTypes.string,
    timezone: PropTypes.string,

    onNext: PropTypes.func.isRequired,
  };

  constructor( props ) {
    super( props );

    this.state = {
      isPasswordVisible: false,
      company: props.company,
      domain: props.domain,
      country: props.country,
      timezone: props.timezone,
      language: props.language,
      validated: {
        company: undefined,
        domain: undefined
      },
    };
  }

  componentWillReceiveProps( nextProps ) {
    this.setState( {
      company: nextProps.company,
      domain: nextProps.domain,
      country: nextProps.country,
      timezone: nextProps.timezone,
      language: nextProps.language,
    } );
  }

  componentWillUnmount() {
    if( this.timeout ) {
      clearTimeout( this.timeout );
    }
  }

  isValid() {
    return Object.keys( this.state.validated ).filter( k => !this.state.validated[ k ] ).length === 0;
  }

  validateDomain( domain ) {
    if ( !domain || domain === '' ) {
      return this.setState( {
        validated: {
          ...this.state.validated,
          domain: undefined,
        },
      } );
    }

    if ( this.timeout ) {
      clearTimeout( this.timeout );
    }
    this.timeout = setTimeout( () =>
      fetch(
        CHECK_DOMAIN_URL(),
        {
          ...POST,
          body: JSON.stringify( { domain } ),
        }
      )
        .then( ( response ) => {
          if ( !response.ok ) {
            return Promise.reject();
          }
          return response;
        } )
        .then( ( response ) => response.json() )
        .then( ( data ) =>
            this.setState((prevState) => {
              return {validated: {...prevState.validated, domain: data.result}}
            })
      ), 150
    )
  }

  handleCompanyChange = ( company ) => {
    const domain = spinalCase( company || '' );
    this.setState( {
      company,
      domain,
      validated: {
        ...this.state.validated,
        company: Boolean( company && company.length ),
      },
    }, () => this.validateDomain( domain ) )

    ;

  };

  handleDomainChange = ( domain ) => {
    this.setState( { domain } );
    this.validateDomain( domain );
  }

  handleCompanyBlur = () => this.validateDomain( this.state.domain );
  handleDomainBlur = () => this.validateDomain( this.state.domain );

  handleNext = () => {
    const {
      domain,
      company,
      country,
      language,
      timezone,
    } = this.state;

    this.props.onNext( {
      domain,
      company,
      country,
      language,
      timezone,
    } );
  }


  renderValidationIcon( value ) {
    if ( value === undefined ) {
      return null;
    }

    return (
      <Icon src={ value ? checkmarkSrc : crossSrc } />
    )
  }


  render() {
    const buttonDisabled = !this.isValid();
    const { validated } = this.state;

    return (
      <div style={ styles.container }>
        <PageHeading>Make it your own.</PageHeading>
        <PageSubHeading>
        </PageSubHeading>
        <FormContainer>
          <FormContainer>

            <FormRow>
              <FormItem>
                <Label>Name your Learning Academy</Label>
                <TextInputField
                  value={ this.state.company }
                  onChange={ this.handleCompanyChange }
                  onBlur={ this.handleCompanyBlur }
                >
                  {
                    this.renderValidationIcon( validated.company )
                  }
                </TextInputField>
              </FormItem>
            </FormRow>

            <FormRow>
              <FormItem>
                <Label>Set the Address to your Learning Academy</Label>
                <FormRow>
                  <FormItem style={ styles.noPadding }>
                    <TextInputField
                      value={ this.state.domain }
                      onChange={ this.handleDomainChange }
                      onBlur={ this.handleDomainBlur }
                      spellCheck={ false }
                    >
                      {
                        this.renderValidationIcon( validated.domain )
                      }
                    </TextInputField>
                  </FormItem>
                  <FormItem style={ [ styles.noPadding, styles.noFlex ] }>
                    <Centered>
                      <span style={ styles.domainSuffix }>.learnifier.se</span>
                    </Centered>
                  </FormItem>
                </FormRow>
              </FormItem>
            </FormRow>
          </FormContainer>
          <FormRow style={ styles.buttonRow }>
            <SlabButton disabled={ buttonDisabled } onClick={ this.handleNext }>START FREE TRIAL NOW</SlabButton>
          </FormRow>
        </FormContainer>
        <Disclaimer>
          { 'By clicking Start Free Trial Now, I agree to the ' }
          <a
            href="https://learnifier.com/terms-of-service/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Service
          </a>
          { ' and ' }
          <a
            href="https://learnifier.com/privacy-policy/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>.
        </Disclaimer>
      </div>
    );
  }

}

Object.assign( styles, {
  container: {
    height: '100%',
    minHeight: '100%',
  },

  heading: {
    padding: '0 10%'
  },

  noPadding: {
    padding: 0,
  },

  icon: {
    display: 'block',
  },

  buttonRow: {
    marginTop: '1.7rem'
  },

  domainSuffix: {
    display: 'table-cell',
    fontFamily: 'Courier New',
    fontWeight: 600,
    fontSize: '1.2rem',
    paddingLeft: '0.6rem',
    paddingTop:' 10px',
    '@media (max-width: 500px)': {
      display: 'block',
      marginTop: '0.8rem'
    },
  },
} );
