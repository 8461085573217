import React from 'react';
import {PageHeading} from '../widgets/headings.js';
import {Disclaimer} from '../widgets';
import './thankYouPage.css';
import {GRAY_HELP} from '../colors';

const styles = {};

export const AutologinFailed = () => {

  return (
        <div className="contres" style={ styles.container }>
          <span style={ styles.filler } />
              <PageHeading>Could not redirect</PageHeading>
              <img style={styles.animatedImg} src={require('../img/404_SquirrelMishap_Animated_1500px@2x-transparent.png')} alt={'Error 404...'}/>
          <p style={ styles.info }>
            When we are ready with the setup, you will receive an email with the login details. Check your inbox, your email invite might already be in it.
          </p>
          <Disclaimer>
            { 'If you didn’t get an email, please check your spam folder and then contact our ' }
            <a href="mailto:support@learnifier.com">support team</a>.
          </Disclaimer>
          <span style={ styles.filler } />
        </div>
  );
};

Object.assign( styles, {
  container: {
  maxWidth: '100%',
  paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  info: {
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight:600,
    marginTop: '2rem',
    color: GRAY_HELP
  },

  filler: {
    flex: '1 0 0',
  },
  animatedImg: {
    maxWidth: '100%',
    textAlign: 'center'
  },

  envelope: {
    position: 'relative',
    overflow: 'hidden'
  },

  thankYou: {
    width: '100%',
  },
}
);
