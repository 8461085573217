import React, { Component } from 'react';
import { StyleRoot } from 'radium';

import RealmTrialEnded from '../pages/RealmTrialEnded.js';
import RealmDisabled from '../pages/RealmDisabled.js';
import RealmCancelled from '../pages/RealmCancelled.js';
import { LEARNIFIER_RED } from '../../colors.js';
import learnifierLogoSrc from '../../img/learnifier-2023.svg';

const styles = {};

class RealmMessage extends Component {

  constructor( props ) {
    super( props );
    const mode = props.match.params.mode;
    console.log("Mode = ", mode);
    this.state = {
      mode
    };
  }

  componentDidMount() {
  }

  renderActivePage( mode ) {
    switch( mode ) {
      case "trialEnded": {
        return(
          <RealmTrialEnded />
        )
      }
      case "cancelled": {
        return(
          <RealmCancelled />
        )
      }
      case "disabled": {
        return(
          <RealmDisabled />
        )
      }
      default:
        return null;

    }
  }
  render() {
    const { mode } = this.state;
    const content = this.renderActivePage( mode );
    return (
      <StyleRoot style={ styles.container }>
        <div style={ styles.logoContainer }>
          <img
            src={ learnifierLogoSrc }
            style={ styles.logo }
            role="presentation"
          />
        </div>
        <div style={ styles.pageContainer }>
          { content }
        </div>
      </StyleRoot>
    );
  }
}

Object.assign( styles, {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: '100%',
    backgroundColor: LEARNIFIER_RED,
    padding: '2rem',

    '@media (max-width: 500px)': {
      height: 'auto',
      padding: '2rem 1rem',
    }
  },

  pageContainer: {
    maxWidth: '40rem',
    minWidth: '40rem',
    margin: 'auto',

    '@media (max-width: 500px)': {
      minWidth: 'initial',
    },
  },

  logoContainer: {
    textAlign: 'left',
    '@media (max-width: 500px)': {
      textAlign: 'center',
      marginBottom: '2rem'
    }
  },

  logo: {
    width: '12rem'
  },
} );

export default RealmMessage;
