// colors
export const RED = '#d75952';
export const PINK = '#f7dbe1';
export const ORANGE = '#db9e47';
export const YELLOW = '#f3d947';
export const YELLOW_LIGHT = '#f8ebdc';

export const GREEN = '#8bad82';
export const GREEN_HOVER = '#577b4f';
export const GREEN_LIGHT = '#ddece0';

export const BLUE = '#289FD0';
export const BLUE_HOVER = '#4C769D';

export const BLUE_LIGHT = '#d5e0ec';
export const BLUE_DARK = '#2e415e';


// grays
export const GRAY_1 = '#f5f5f5';
export const GRAY_2 = '#ebebeb';
export const GRAY_3 = '#e3e3e0';

export const GRAY_MEDIUM_1 = '#cecdc8';
export const GRAY_MEDIUM_2 = '#6b849c';
export const GRAY_MEDIUM_3 = '#585858';

export const GRAY_HELP = '#6e6e6a';

export const BLACK = '#333';
export const WHITE = '#ffffff';


export const LEARNIFIER_RED = RED; //'#de5243';
export const LEARNIFIER_BLUE = BLUE; //'#79cacd';
export const LEARNIFIER_BLUE_HOVER = BLUE_DARK; //'#54bbbf';

export const DISABLED_GREY = GRAY_1; //'#d4d2d2';
